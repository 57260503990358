function updatePreferences(cookie, change = false)
{
    let preferences = {
        functionality_storage: 'denied',
        security_storage: 'denied',
        analytics_storage: 'denied',
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied'
    };

    cookie.categories.forEach(category => {
        if (category === 'necessary') {
            preferences.functionality_storage = 'granted';
            preferences.security_storage = 'granted';
        }
        if (category === 'analytics') {
            preferences.analytics_storage = 'granted';
        }
        if (category === 'advertising') {
            preferences.ad_storage = 'granted';
            preferences.ad_user_data = 'granted';
            preferences.ad_personalization = 'granted';
        }
    });

    if (preferences) {
        window.dataLayer = window.dataLayer || [];

        function gtag()
        {
            window.dataLayer.push(arguments);
        }

        gtag('consent', 'update', preferences);

        if (change) {
            setTimeout(function () {
                window.location.reload();
            }, 1000);
        }
    }
}

CookieConsent.run({
    onFirstConsent: ({cookie}) => updatePreferences(cookie),
    onConsent: ({cookie}) => updatePreferences(cookie),
    onChange: ({cookie}) => updatePreferences(cookie, true),
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            readOnly: true
        },
        analytics: {},
        advertising: {}
    },
    language: {
        default: "cs",
        autoDetect: "browser",
        translations: {
            cs: {
                consentModal: {
                    title: "Používáme cookies",
                    description: "Naše webové stránky používají soubory cookie. Soubory cookie jsou textové soubory, které jsou ukládány ve webovém prohlížeči nebo webovým prohlížečem v počítačovém systému uživatele. Pokud uživatel vyvolá webovou stránku, může být soubor cookie uložen v operačním systému uživatele. Tento soubor cookie obsahuje charakteristický řetězec, který umožňuje jednoznačnou identifikaci prohlížeče při opětovném vyvolání webové stránky.",
                    acceptAllBtn: "Souhlasím se vším",
                    acceptNecessaryBtn: "Odmítnout vše",
                    showPreferencesBtn: "Spravovat nastavení",
                    footer: ""
                },
                preferencesModal: {
                    title: "Centrum předvoleb souhlasu",
                    acceptAllBtn: "Souhlasím se vším",
                    acceptNecessaryBtn: "Odmítnout vše",
                    savePreferencesBtn: "Uložit nastavení",
                    closeIconLabel: "Zavřít okno",
                    sections: [
                        {
                            title: "Používání souborů cookie",
                            description: "Naše webové stránky používají soubory cookie. Soubory cookie jsou textové soubory, které jsou ukládány ve webovém prohlížeči nebo webovým prohlížečem v počítačovém systému uživatele. Pokud uživatel vyvolá webovou stránku, může být soubor cookie uložen v operačním systému uživatele. Tento soubor cookie obsahuje charakteristický řetězec, který umožňuje jednoznačnou identifikaci prohlížeče při opětovném vyvolání webové stránky."
                        },
                        {
                            title: "Nezbytné Cookies <span class=\"pm__badge\">Vždy zapnuté</span>",
                            description: "Nezbytné soubory cookies se používají k údržbě našich služeb. Zajišťují například technický výkon našich webových stránek, pomáhají nám zabezpečit webové stránky nebo předcházet podvodům. Nezbytné soubory cookie nelze zakázat.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Analytické Cookies",
                            description: "Analytické soubory cookies nám umožňují počítat návštěvy a návštěvnost webu, takže můžeme shromažďovat informace, například o tom, které stránky jsou nejoblíbenější, a sledovat, jak se návštěvníci na webu pohybují.",
                            linkedCategory: "analytics"
                        },
                        {
                            title: 'Marketingové Cookies',
                            description: 'Marketingové cookies využíváme my i naši partneři k tomu, abychom vám mohli poskytovat personalizované nabídky, obsah a reklamy, a to nejen na našich stránkách, ale také na stránkách třetích stran a na sociálních sítích.',
                            linkedCategory: 'advertising',
                        }
                    ]
                }
            }
        }
    }
});
